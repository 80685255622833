import { string } from "i/lib/util";
import React from "react";

const EditorConfig = {
  uploader: {
    insertImageAsBase64URI: true,
    imageAlt: string,
  },
  pasteFormat: 'html',
  buttons: [
    "source",
    "|",
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "eraser",
    "|",
    "ul",
    "ol",
    "|",
    "fontsize",
    "font",

    "paragraph",
    "classSpan",
    "lineHeight",    
    "|",
    "subscript",
    "superscript",
    "image",
    "video",
    "|",
    "spellcheck",
    "|",
    "cut",
    "copy",
    "paste",
    "selectall",
    "copyformat",
    "|",
    "hr",
    "table",
    "link",
    "symbols",
    "|",
    "outdent",
    "indent",
    "align",
  
    "|",
    "brush",
    "|",
    "undo",
    "redo",
    "|",
    "find",
    "|",
    "fullsize",
    "preview",
    "print",
    "about",
  ],
  buttonsXS: [
    "bold",
    "image",
    "|",
    "brush",
    "paragraph",
    "|",
    "align",
    "|",
    "undo",
    "redo",
    "|",
    "eraser",
    "dots",
  ],
  controls: {
    font: {
        list: {
          'Arial,Helvetica,sans-serif': 'Arial',
          'Georgia,serif': 'Georgia',
          'Impact,Charcoal,sans-serif': 'Impact',
          'Poppins,sans-serif': 'Poppins',
          'Roboto,sans-serif': 'Roboto',
          'Roboto-Bold,sans-serif': 'Roboto-Bold',
          'Tahoma,Geneva,sans-serif': 'Tahoma',
          'Times New Roman,Times,serif': 'Times New Roman',
          'Verdana,Geneva,sans-serif': 'Verdana'
        }
    }
  },
  alt: true,
};

export default EditorConfig;
