import React, { useState, useRef, useEffect, useContext } from "react";
import { Form, Label, Input } from "reactstrap";
import JoditEditor from "jodit-react";
import { ApiContext } from "../apiContext";
import EditorConfig from "../../helper/JoditEditor";

const AddForm = ({
  handleSubmit,
  formError,
  getInputValue,
  handleInputChange,
  handleClear,
  modalData,
  addCase,
  setModalData,
  contentDataChanged,
  setCase,
  closeModal,
}) => {
  const editor = useRef(null);
  const [editorConfig, setEditorConfig] = useState(null);
  useEffect(() => {
    // Initialize Jodit editor
    setEditorConfig(EditorConfig);
  }, []);
  const { error } = useContext(ApiContext);
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="row edit-form">
          <div className="my-3 col-md-12">
            <Label htmlFor="url">Title</Label>
            <Input
              type="text"
              id="title"
              name="title"
              placeholder="Title"
              value={getInputValue("title")}
              onChange={(event) => handleInputChange(event, "title")}
            />
            <span className="non-valid">{formError.title}</span>
          </div>

          <div className="my-3 col-md-12">
            <Label htmlFor="url">Definition</Label>
            <textarea
              type="text"
              id="shortdescription"
              name="shortdescription"
              value={getInputValue("shortdescription")}
              onChange={(event) => handleInputChange(event, "shortdescription")}
            />
          </div>
          <div className="my-3 col-md-6 email">
            <Label for="email-confi">Do you need a description?</Label>
            <Input
              type="checkbox"
              id="email-confi"
              name="email-confi"
              value="email-confi"
              checked={modalData?.descriptionflag || addCase?.descriptionflag}
              onChange={() => {
                if (modalData) {
                  setModalData((prevModalData) => ({
                    ...prevModalData,
                    descriptionflag: !prevModalData.descriptionflag,
                  }));
                } else {
                  setCase((prevAddCase) => ({
                    ...prevAddCase,
                    descriptionflag: !prevAddCase.descriptionflag,
                  }));
                }
              }}
            />
          </div>
          <div className="my-3  col-md-6 email">
            <Label for="email-confi">Are you need custom url?</Label>
            <Input
              type="checkbox"
              id="email-confi"
              name="email-confi"
              value="email-confi"
              checked={modalData?.customurlflag || addCase?.customurlflag}
              onChange={() => {
                if (modalData) {
                  setModalData((prevModalData) => ({
                    ...prevModalData,
                    customurlflag: !prevModalData.customurlflag,
                  }));
                } else {
                  setCase((prevAddCase) => ({
                    ...prevAddCase,
                    customurlflag: !prevAddCase.customurlflag,
                  }));
                }
              }}
            />
          </div>
          {/* Render email-show block */}
          {(modalData?.descriptionflag || addCase?.descriptionflag) && (
            <div className="email-show">
              <div className="my-3 col-md-12">
                <Label for="url">Description</Label>
                <JoditEditor
                  ref={editor}
                  config={editorConfig}
                  value={modalData?.description || ""}
                  onChange ={(event) => contentDataChanged(event, "description")}
                />
              </div>
            </div>
          )}

          {/* Render email-show block */}
          {(modalData?.customurlflag || addCase?.customurlflag) && (
            <div className="email-show">
              <div className="my-3 col-md-12">
                <Label for="url">Custom Url</Label>
                <Input
                  type="text"
                  id="custom url"
                  name="custom url"
                  value={getInputValue("customurl")}
                  onChange={(event) => handleInputChange(event, "customurl")}
                  disabled
                />
              </div>
            </div>
          )}

          <div className="my-3 col-md-6">
            <Label htmlFor="status">Status</Label>
            <div className="toggle">
              <span>Inactive</span>
              <label
                className={`switch ${
                  (modalData && modalData.status) || addCase.status
                    ? "true"
                    : ""
                }`}
              >
                <Input
                  type="checkbox"
                  checked={modalData && modalData.status}
                  onChange={() => {
                    if (modalData) {
                      setModalData((prevModalData) => ({
                        ...prevModalData,
                        status: !prevModalData.status,
                      }));
                    } else {
                      setCase((prevAddCase) => ({
                        ...prevAddCase,
                        status: !prevAddCase.status,
                      }));
                    }
                  }}
                />
                <span className="slider round"></span>
              </label>
              <span>Active</span>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning ms-2"
              onClick={handleClear}
            >
              Clear
            </button>
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-primary ms-2"
            >
              Submit
            </button>
          </div>
        </div>
      </Form>
    </>
  );
};
export default AddForm;
