import React, { useState, useRef, useContext, useEffect } from "react";
import { Form, Label, Input } from "reactstrap";
import JoditEditor from "jodit-react";
import { ApiContext } from "../apiContext";
import Select from "react-select";
import EditorConfig from "../../helper/JoditEditor";

const AddForm = ({
  handleSubmit,
  formError,
  getInputValue,
  handleInputChange,
  contentDataChanged,
  handleImageUpload,
  handleClear,
  modalData,
  addCase,
  setModalData,
  setCase,
  ebookscategory,


}) => {
  const editor = useRef(null);
  const [editorConfig, setEditorConfig] = useState(null);
  useEffect(() => {
    setEditorConfig(EditorConfig);
  }, []);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const { error } = useContext(ApiContext);
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="row edit-form">
          <div className="my-3 col-md-6">
            <Label htmlFor="url">Title</Label>
            <Input
              type="text"
              id="title"
              name="title"
              placeholder="Title"
              value={getInputValue("title")}
              onChange={(event) => handleInputChange(event, "title")}
            />
            <span className="non-valid">{formError.title}</span>
          </div>
          <div className="my-3 col-md-6">
            <label htmlFor="category">Category</label>
            <Select
              name="category"
              options={
                ebookscategory &&
                ebookscategory
                  .filter((option) => option.Status === true)
                  .map((option) => ({
                    value: option.Code,
                    label: option.Name,
                  }))
              }
              value={
                !modalData
                  ? selectedOptions
                  : modalData?.category && // Check if modalData.category is an array
                    modalData.category.map((category) => ({
                      value: category,
                      label: ebookscategory.find(
                        (option) => option.Code === category
                      )?.Name,
                    }))
              }
              onChange={(selectedOptions) => {
                if (!modalData) {
                  setSelectedOptions(selectedOptions);
                  setCase((prevData) => ({
                    ...prevData,
                    category: selectedOptions.map((option) => option.value),
                  }));
                } else {
                  const selectedValues = selectedOptions.map(
                    (option) => option.label
                  );
                  handleInputChange(
                    {
                      target: { value: selectedValues.join(", ") },
                    },
                    "category"
                  );
                  setModalData((prevData) => ({
                    ...prevData,
                    category: selectedOptions.map((option) => option.value),
                  }));
                }
              }}
              isMulti
              isSearchable
            />
            <span className="non-valid">{formError.category}</span>
          </div>

          <div className="my-3 col-md-6">
            <Label htmlFor="url">Author</Label>
            <Input
              type="text"
              id="author"
              name="author"
              placeholder="Author"
              value={getInputValue("author")}
              onChange={(event) => handleInputChange(event, "author")}
            />
            <span className="non-valid">{formError.author}</span>
          </div>

          <div className="my-3 col-md-6">
            <Label htmlFor="status">Status</Label>
            <div className="toggle">
              <span>Inactive</span>
              <label
                className={`switch ${
                  (modalData && modalData.status) || addCase.status
                    ? "true"
                    : ""
                }`}
              >
                <Input
                  type="checkbox"
                  checked={modalData && modalData.status}
                  onChange={() => {
                    if (modalData) {
                      setModalData((prevModalData) => ({
                        ...prevModalData,
                        status: !prevModalData.status,
                      }));
                    } else {
                      setCase((prevAddCase) => ({
                        ...prevAddCase,
                        status: !prevAddCase.status,
                      }));
                    }
                  }}
                />
                <span className="slider round"></span>
              </label>
              <span>Active</span>
            </div>
          </div>
          <div className="my-3 col-md-12">
            <Label for="description">Description</Label>
            <JoditEditor
              ref={editor}
              name="description"
              config={editorConfig}
              value={modalData?.description || ""}
              onChange={(event) => contentDataChanged(event, "description")}
            />
          </div>
          <div className="my-3 col-md-6">
            <Label for="imagefile">
              Select Ebook Image (Upload only images up to 100kb)
            </Label>
            <div className="file-field">
              <div className="btn helensys-theme-btn btn-sm ml-0">
                <span>Choose File</span>
                <Input
                  id="documentuplad"
                  name="imagefile"
                  type="file"
                  onChange={(event) =>
                    handleImageUpload(event, "thumbnail_image")
                  }
                  accept="image/gif, image/jpeg, image/png, image/webp*"
                  encType="multipart/form-data"
                />
              </div>
              <div className="file-path-wrapper">
                <Input
                  className="file-path validate"
                  value={
                    modalData
                      ? modalData.thumbnail_image_name
                      : addCase.thumbnail_image_name
                  }
                  type="text"
                  placeholder=""
                  onChange={(event) =>
                    handleInputChange(event, "thumbnail_image_name")
                  }
                />
              </div>
            </div>
            <span className="non-valid">{formError.thumbnail_image}</span>
          </div>
          <div className="my-3 col-md-6">
            <Label for="imagefile">
              Select Ebook Pdf (Upload only Pdf upto 5mb)
            </Label>
            <div className="file-field">
              <div className="btn helensys-theme-btn btn-sm ml-0">
                <span>Choose File</span>
                <Input
                  id="documentuplad"
                  name="pdffile"
                  type="file"
                  onChange={(event) =>
                    handleImageUpload(event, "thumbnail_pdf")
                  }
                  accept="image/pdf*"
                  encType="multipart/form-data"
                />
              </div>
              <div className="file-path-wrapper">
                <Input
                  className="file-path validate"
                  value={
                    modalData
                      ? modalData.thumbnail_pdf_name
                      : addCase.thumbnail_pdf_name
                  }
                  type="text"
                  placeholder=""
                  onChange={(event) =>
                    handleInputChange(event, "thumbnail_pdf_name")
                  }
                />
              </div>
            </div>
            <span className="non-valid">{formError.thumbnail_pdf}</span>
          </div>
          <div className="my-3 email">
            <Label for="email-confi">Email Configuration</Label>
            <Input
              type="checkbox"
              id="email-confi"
              name="email-confi"
              value="email-confi"
              checked={
                modalData?.emailconfigurationflag ||
                addCase?.emailconfigurationflag
              }
              onChange={() => {
                if (modalData) {
                  setModalData((prevModalData) => ({
                    ...prevModalData,
                    emailconfigurationflag:
                      !prevModalData.emailconfigurationflag,
                  }));
                } else {
                  setCase((prevAddCase) => ({
                    ...prevAddCase,
                    emailconfigurationflag: !prevAddCase.emailconfigurationflag,
                  }));
                }
              }}
            />
          </div>
          {/* Render email-show block */}
          {(modalData?.emailconfigurationflag ||
            addCase?.emailconfigurationflag) && (
            <div className="email-show">
              <div className="my-3 col-md-12">
                <Label for="url">Email send schedule in days</Label>
                <Input
                  type="number"
                  id="alttext"
                  name="email schedule"
                  value={getInputValue("sendemaildays")}
                  onChange={(event) =>
                    handleInputChange(event, "sendemaildays")
                  }
                />
              </div>
              <div className="my-3 col-md-12">
                <Label for="url">Email Template</Label>
                <JoditEditor
                  ref={editor}
                  config={editorConfig}
                  value={modalData?.emailtemplate || ""}
                  // onBlur={(newContent) =>
                  //   handleInputChange(
                  //     { target: { value: newContent } },
                  //     "emailtemplate"
                  //   )
                  // }
                  onChange={(event) => contentDataChanged(event, "emailtemplate")}
                />
              </div>
            </div>
          )}

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning ms-2"
              onClick={handleClear}
            >
              Clear
            </button>
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-primary ms-2"
            >
              Submit
            </button>
          </div>
        </div>
      </Form>
    </>
  );
};
export default AddForm;
