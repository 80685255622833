import React, { useContext, useState, useEffect } from "react";
import { ApiContext } from "../apiContext";
import { FaEye, FaEdit, FaTrashAlt } from "react-icons/fa";
import Service from "./Service";
import ViewEbook from "./ViewEbook";
import axios from "axios";
import { getToken } from "../../auth/AuthService";
import DeleteBox from "../DeleteBox";
import { useLoader } from "../../helper/Loader";
import notify from "../../helper/Notify";

function EbookApi({ page = 1, pageSize, searchQuery, totalLength }) {
  const { data, setData, error } = useContext(ApiContext);
  const [filteredBlog, setFilteredBlog] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [EditModal, setEditModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const token = getToken();
  const closeModal = () => setshowModal(false);
  const modalClose = () => setEditModal(false);
  const apiBaseUrl = window.API_CONFIG.All_Api.ebooks;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCandidate, setDeleteCandidate] = useState(null);
  const {setIsLoader} = useLoader();

  useEffect(() => {
    try {
      if (!data.ebooks || data.ebooks.length === 0) {
        throw new Error("No data available");
      }

      const filteredData = data.ebooks.filter((item) => {
        const name = item?.title ?? "";
        return name.toLowerCase().includes(searchQuery.toLowerCase());
      });

      const sortedData = filteredData.sort(
        (a, b) =>{
          const dateA = a.updatedDate ? new Date(a.updatedDate) : new Date(a.createdDate);
          const dateB = b.updatedDate ? new Date(b.updatedDate) : new Date(b.createdDate);
          return dateB - dateA; 
    });

      setFilteredBlog(sortedData);
      totalLength(filteredData.length);
    } catch (error) {
      console.error("Error fetching or processing data:", error);
    }
  }, [data.ebooks, searchQuery, page, pageSize, totalLength]);

  function generateApiUrl(fileName) {
    return `${apiBaseUrl}/${fileName}`;
  }
  /*Handle Remove function */

  /* Handle Remove function */
  const confirmDelete = async () => {
    setShowDeleteModal(false);
    const apiUrl = `${apiBaseUrl}/${deleteCandidate}`;
    setIsLoader(true)
    try {
      const response = await axios.delete(apiUrl);
      console.log("Delete response:", response.data);

     
      const updatedDataResponse = await axios.get(apiBaseUrl, {
        headers: {
          "x-access-token": token,
        },
      });
      const updatedDataFromServer =
        updatedDataResponse.data.data?.ViewModels || [];

      setData((prevData) => ({
        ...prevData,
        ebooks: updatedDataFromServer,
      }));
      notify.success("Data removed successfully")
    } catch (error) {
      // Handle any errors here
      console.error("Error deleting data:", error);
    }
    setIsLoader(false);
  };
  const handleRemover = async (id) => {
    setDeleteCandidate(id);
    setShowDeleteModal(true);
  };
  const cancelDelete = () => {
    setShowDeleteModal(false);
    setDeleteCandidate(null);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const items = filteredBlog
      .slice(startIndex, endIndex)
      .map((viewModel, index) => {
        const id = `item-${index}`,
          apiUrl = generateApiUrl(viewModel.fileName);
        const fetchData = async () => {
          try {
            const response = await fetch(apiUrl);
            const data = await response.json();
            // Process the fetched data here

            setModalData(data.data);
          } catch (error) {
            // Handle any errors that occur during the fetch
            console.error(error);
          }
        };
        return (
          <tr key={id}>
            <td
              title="View"
              onClick={() => {
                setshowModal(true);
                fetchData();
              }}
            >
              <FaEye />
            </td>
            <td
              title="Edit"
              onClick={() => {
                setEditModal(true);
                fetchData();
              }}
            >
              <FaEdit />
            </td>
            <td
              title="Delete"
              onClick={() => handleRemover(viewModel.fileName)}
            >
              <FaTrashAlt />
            </td>
            <td title = {viewModel.title} width="25%">
              {viewModel && viewModel.title
                ? `${viewModel.title.substring(0, 40)}...`
                : "Title not available"}
            </td>
            <td width="33%">
              {viewModel.description ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      viewModel.description.length > 100
                        ? viewModel.description.substring(0, 100) + "..."
                        : viewModel.description,
                  }}
                />
              ) : (
                "Description not available"
              )}
            </td>
            <td width="16%">
              {new Date(viewModel.updatedDate || viewModel.createdDate)
                .toISOString()
                .slice(0, 10)}
            </td>
            <td width="10%">
              <span
                className={`status ${viewModel.status ? "active" : "inactive"}`}
              >
                {viewModel.status ? "active" : "inactive"}
              </span>
            </td>
            {showModal && (
              <ViewEbook Modalclose={closeModal} modalData={modalData} />
            )}
            {EditModal && (
              <Service
                closeModal={modalClose}
                modalData={modalData}
                setModalData={setModalData}
              />
            )}
          </tr>
        );
      });

    const caseItems = (
      <tbody>
        {filteredBlog.length === 0 ? (
          <tr>
          <td classname ="noFound" colSpan="12">No data found</td>
          </tr>
        ) : (
          items
        )}
        {showDeleteModal && (
          <DeleteBox Modalclose={cancelDelete} handleDelete={confirmDelete} />
        )}
      </tbody>
    );
    return caseItems;
  }
}

export const EbookLength = () => {
  const { data } = useContext(ApiContext);
  return data.ebooks.length;
};

export default EbookApi;
