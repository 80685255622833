import React, { useState, useEffect, useContext } from "react";
import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";
import { ApiContext } from "../apiContext";
import AddForm from "./AddForm";
import { getToken } from "../../auth/AuthService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useLoader } from "../../helper/Loader";
import notify from "../../helper/Notify";
import { isSpecialCharacter } from "../../helper/CommonFunction";

const Service = ({ closeModal, modalData, setModalData, updateTableData }) => {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  const { data, error, setData } = useContext(ApiContext);
  const [formError, setFormError] = useState({});
  const apiBaseUrl = window.API_CONFIG.All_Api.webinar;
  const token = getToken();
  const {setIsLoader} = useLoader();

  const generateUUID = () => {
    const uuid = uuidv4();
    const fiveDigitUUID = uuid.replace(/-/g, "").substr(0, 5);
    return fiveDigitUUID;
  };
  const [addCase, setCase] = useState({
    title: "",
    author: "",
    category: [],
    description: "",
    contentUrl: "",
    durationminute: "",
    durationsecond: "",
    embedUrl: "",
    code: generateUUID(),
  });
  /*---- get all the input field ----*/

  const getInputValue = (field) => {
    return modalData ? modalData[field] : addCase[field];
  };
  /*---- change input field function ----*/
  const handleInputChange = (event, field) => {
    const { value } = event.target;
    if (modalData) {
      setModalData((prevModalData) => ({
        ...prevModalData,
        [field]: value,
      }));
    } else {
      setCase((prevAddCase) => ({
        ...prevAddCase,
        [field]: value,
      }));
    }
  };

  /*---- handle clear function----*/
  const handleClear = () => {
    const clearfields = {
      title: "",
      author: "",
      description: "",
      contentUrl: "",
      durationminute: "",
      durationsecond: "",
      embedUrl: "",
      thumbnailurl: "",
      status: false,
    };
    if (!modalData) {
      setCase(clearfields);
    } else {
      setModalData((prevModalData) => ({
        ...prevModalData,
        ...clearfields,
      }));
    }
  };

  /*---- validation on form fields----*/
  const validateFormFields = () => {
    const errors = {};
    const fieldarr = [
      "title",
      "author",
      "contentUrl",
      "embedUrl",
      "durationminute",
      "durationsecond",
    ];
    const validatefield = (field) => {
      if (
        !modalData &&
        (addCase[field] === "" ||
          (Array.isArray(addCase[field]) && addCase[field].length === 0))
      ) {
        errors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required.`;
      }
      if (
        modalData &&
        (modalData[field] === "" ||
          (Array.isArray(modalData[field]) && modalData[field].length === 0))
      ) {
        errors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required.`;
      }
    };
    fieldarr.forEach(validatefield);

    return errors;
  };

  /* form submit function*/
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formErrors = validateFormFields();

    if (Object.keys(formErrors).length > 0) {
      setFormError(formErrors);
      notify.warning();
      return; 
    }

    setFormError(""); 
    setIsLoader(true)

    try {
      if (modalData) {
        const updatedData = {
          ...modalData,
          updatedDate: new Date().toISOString().slice(0, 10),
        };

        try {
          const response = await axios.get(apiBaseUrl, {
            headers: {
              "x-access-token": token,
            },
          });

          const careerData = response.data.data?.ViewModels || [];
          const updatedCareerData = careerData.map((item) =>
            item.code === modalData.code ? { ...item, ...updatedData } : item
          );
          const duplicateItem = careerData.find(
            (item) =>
              item.title === modalData.title && item.code !== modalData.code
          );

          if (duplicateItem) {
            notify.error("Data is a duplicate and cannot be updated.");
            return;
          }

          if (isSpecialCharacter(modalData.title.charAt(0))){
            notify.error("Name should not start with special character");
               return;
          }

          const updateResponse = await axios.post(
            apiBaseUrl,
            { ViewModels: updatedCareerData },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-access-token": token,
              },
            }
          );

          console.log("Data successfully updated:", updateResponse.data);
          if(updateResponse.data.success === true){
          setData((prevData) => ({
            ...prevData,
            webinar: updatedCareerData,
          }));
          closeModal();
          notify.success(updateResponse.data.message)

          setCase({});
        }
        else{
          notify.error(updateResponse.data.message)
        }
        } catch (error) {
          console.error("Error updating data:", error);
          notify.error(error.message);
        }
      } else {
        // Adding a new data entry
        const updatedBlogData = {
          ...addCase,
          createdDate: new Date().toISOString().slice(0, 10),
        };

        try {
          const response = await axios.get(apiBaseUrl, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "x-access-token": token,
            },
          });

          const oldData = response.data.data?.ViewModels || [];
          const duplicateItem = oldData.find(
            (item) => item.title === addCase.title
          );

          if (duplicateItem) {
            notify.error("Data is a duplicate and cannot be added.");
            return;
          }

          if (isSpecialCharacter(addCase.title.charAt(0))){
            notify.error("Name should not start with special character");
               return;
          }

          const updatedData = [...oldData, updatedBlogData];

          const addResponse = await axios.post(
            apiBaseUrl,
            { ViewModels: updatedData },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-access-token": token,
              },
            }
          );

          console.log("New data added successfully:", addResponse.data);
          if(addResponse.data.success === true){
          setData((prevData) => ({
            ...prevData,
            webinar: updatedData,
          }));

          closeModal();
          notify.success(addResponse.data.message);
          setCase({});
        }
        else{
          notify.error(addResponse.data.message);
        }
        } catch (error) {
          console.error("Error adding new data:", error);
          notify.error(error.message);
        }
      }
    } catch (error) {
      console.error("Error occurred:", error);
      notify.error(error.message);
    }
    finally{
      setIsLoader(false)
    }
  };
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <>
      <div className="modal-wrapper" onClick={closeModal}></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>{modalData ? "Edit Webinar" : "Add New Webinar"}</h1>
          <div className="close-icon">
            <FaWindowClose onClick={closeModal} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <AddForm
                handleSubmit={handleSubmit}
                formError={formError}
                getInputValue={getInputValue}
                handleInputChange={handleInputChange}
                handleClear={handleClear}
                modalData={modalData}
                addCase={addCase}
                setModalData={setModalData}
                setCase={setCase}
                closeModal={closeModal}
                thumbnail_pdf_name={getInputValue("thumbnail_pdf_name")}
              />
            </CardBody>
            <div className="avc"></div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Service;
