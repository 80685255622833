import React from "react";
import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";

export default function ViewForm({ Modalclose, modalData }) {
  if (!modalData) {
    return null; // or return some default content if modalData is null or undefined
  }

  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>MetaData DETAILS</h1>
          <div className="close-icon">
            <FaWindowClose onClick={Modalclose} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <section className="view-blog view-sec">
                <div className="container">
                  <div className="mt-3 view-text">
                    {modalData.url && (
                      <>
                        <label className="heading">Page</label>
                        <p>{modalData.url}</p>
                      </>
                    )}
                    {modalData.title && (
                      <>
                        <label className="heading">Title</label>
                        <p>{modalData.title}</p>
                      </>
                    )}
                    {modalData.metaDescription && (
                      <>
                        <label className="heading">Meta Description</label>
                        <p>{modalData.metaDescription}</p>
                      </>
                    )}
                    {modalData.metaKeywords && (
                      <>
                        <label className="heading">Meta Keywords</label>
                        <p>{modalData.metaKeywords}</p>
                      </>
                    )}

                    {modalData.metaCanonical && (
                      <>
                        <label className="heading">Meta Canonical</label>
                        <p>{modalData.metaCanonical}</p>
                      </>
                    )}
                    {modalData.thumbnail_image && (
                      <>
                        <label className="heading">Meta Image</label>
                        <img src={modalData.thumbnail_image} alt="Thumbnail" />
                      </>
                    )}
                    {modalData.metaIproductname && (
                      <>
                        <label className="heading">Category</label>
                        <p>{modalData.metaIproductname}</p>
                      </>
                    )}
                  </div>
                </div>
              </section>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
