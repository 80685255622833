import React, { useRef, useContext, useState, useEffect } from "react";
import { Form, Label, Input } from "reactstrap";
import JoditEditor from "jodit-react";
import { ApiContext } from "../apiContext";
import EditorConfig from "../../helper/JoditEditor";

const AddForm = ({
  handleSubmit,
  formError,
  getInputValue,
  handleInputChange,
  contentDataChanged,
  handleImageUpload,
  handleClear,
  modalData,
  addCase,
  setModalData,
  setCase,
  caseStudiesCategory,
}) => {
  const editor = useRef(null);
  const [editorConfig, setEditorConfig] = useState(null);
  useEffect(() => {
    // Initialize Jodit editor
    setEditorConfig(EditorConfig);
  }, []);
  const { error } = useContext(ApiContext);
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="row edit-form">
          <div className="my-3 col-md-6">
            <Label for="url">Case Studies URL</Label>
            <Input
              type="text"
              id="url"
              name="url"
              placeholder="URL"
              required
              value={getInputValue("url")}
              onChange={(event) => handleInputChange(event, "url")}
            />
            <span className="non-valid">{formError.url}</span>
          </div>
          <div className="my-3 col-md-6">
            <Label for="category">Category</Label>
            <select
              id="category"
              name="categoryid"
              className="select-list"
              onChange={(event) => handleInputChange(event, "category")}
              value={getInputValue("category")}
            >
              <option value="">-- Select Category --</option>
              {caseStudiesCategory &&
                caseStudiesCategory
                  .filter((option) => option.Status === true)
                  .map((option) => (
                    <option key={option.Code} value={option.Code}>
                      {option.Name}
                    </option>
                  ))}
            </select>
            <span className="non-valid">{formError.category}</span>
          </div>
          <div className="my-3 col-md-6">
            <Label for="url">Title</Label>
            <Input
              type="text"
              id="title"
              name="title"
              placeholder="Title"
              value={getInputValue("title")}
              onChange={(event) => handleInputChange(event, "title")}
            />
            <span className="non-valid">{formError.title}</span>
          </div>
          <div className="my-3 col-md-6">
            <Label for="url">Company Name</Label>
            <Input
              type="text"
              id="companyname"
              name="companyname"
              placeholder="Company Name"
              value={getInputValue("companyname")}
              onChange={(event) => handleInputChange(event, "companyname")}
            />
            <span className="non-valid">{formError.companyname}</span>
          </div>
          <div className="my-3 col-md-6">
            <Label for="url">Case Studies alt text</Label>
            <Input
              type="text"
              id="alttext"
              name="alttext"
              placeholder="Case Studies alt text"
              value={getInputValue("alttext")}
              onChange={(event) => handleInputChange(event, "alttext")}
            />
            <span className="non-valid">{formError.alttext}</span>
          </div>
          <div className="my-3 col-md-6">
            <Label for="status">Status</Label>
            <div className="toggle">
              <span>Inactive</span>
              <label
                className={`switch ${
                  (modalData && modalData.status) || addCase.status
                    ? "true"
                    : ""
                }`}
              >
                <Input
                  type="checkbox"
                  checked={modalData && modalData.status}
                  onChange={() => {
                    if (modalData) {
                      setModalData((prevModalData) => ({
                        ...prevModalData,
                        status: !prevModalData.status,
                      }));
                    } else {
                      setCase((prevAddCase) => ({
                        ...prevAddCase,
                        status: !prevAddCase.status,
                      }));
                    }
                  }}
                />
                <span className="slider round"></span>
              </label>
              <span>Active</span>
            </div>
          </div>
          <div className="my-3 col-md-6">
            <Label for="url">Use Case</Label>
            <textarea
              type="text"
              id="usecase"
              placeholder="Use Case"
              value={getInputValue("usecase")}
              onChange={(event) => handleInputChange(event, "usecase")}
            />
            <span className="non-valid">{formError.usecase}</span>
          </div>
          <div className="my-3 col-md-6">
            <Label for="url">Industry</Label>
            <textarea
              type="text"
              id="industry"
              name="industry"
              placeholder="Industry"
              value={getInputValue("industry")}
              onChange={(event) => handleInputChange(event, "industry")}
            />
            <span className="non-valid">{formError.industry}</span>
          </div>
          <div className="my-3 col-md-6">
            <Label for="url">Region</Label>
            <textarea
              type="text"
              id="region"
              name="region"
              placeholder="region"
              value={getInputValue("region")}
              onChange={(event) => handleInputChange(event, "region")}
            />
            <span className="non-valid">{formError.region}</span>
          </div>
          <div className="my-3 col-md-6">
            <Label for="url">Short Description</Label>
            <textarea
              type="text"
              id="shortdescription"
              name="shortDescription"
              value={getInputValue("shortdescription")}
              onChange={(event) => handleInputChange(event, "shortdescription")}
            />
            <span className="non-valid">{formError.shortdescription}</span>
          </div>
          <div className="my-3 col-md-12">
            <Label for="author-bio">Description</Label>
            <JoditEditor
              ref={editor}
              config={editorConfig}
              tabIndex={1}
              value={modalData?.description || ""}
              onChange={(event) => contentDataChanged(event, "description")}
            />
          </div>
          <div className="my-3 col-md-6">
            <Label for="author-bio">Challenges</Label>
            <JoditEditor
              ref={editor}
              config={editorConfig}
              tabIndex={1}
              value={modalData?.challenges || ""}
              // onBlur={(newContent) =>
              //   handleInputChange(
              //     { target: { value: newContent } },
              //     "challenges"
              //   )
              // }
              onChange={(event) => contentDataChanged(event, "challenges")}
            />
          </div>
          <div className="my-3 col-md-6">
            <Label for="author-bio">Outcome</Label>
            <JoditEditor
              ref={editor}
              config={editorConfig}
              tabIndex={1}
              value={modalData?.outcome || ""}
              // onBlur={(newContent) =>
              //   handleInputChange({ target: { value: newContent } }, "outcome")
              // }
              onChange={(event) => contentDataChanged(event, "outcome")}
            />
          </div>
          <div className="my-3 col-md-6">
            <Label for="imagefile">
              Select CaseStudies Image (Upload only images up to 100kb)
            </Label>
            <div className="file-field">
              <div className="btn helensys-theme-btn btn-sm ml-0">
                <span>Choose File</span>
                <Input
                  id="documentuplad"
                  name="imagefile"
                  type="file"
                  onChange={(event) =>
                    handleImageUpload(event, "thumbnail_image")
                  }
                  accept="image/gif, image/jpeg, image/png, image/webp*"
                  encType="multipart/form-data"
                />
              </div>
              <div className="file-path-wrapper">
                <Input
                  className="file-path validate"
                  value={
                    modalData
                      ? modalData.thumbnail_image_name
                      : addCase.thumbnail_image_name
                  }
                  type="text"
                  placeholder=""
                  onChange={(event) =>
                    handleInputChange(event, "thumbnail_image_name")
                  }
                />
              </div>
            </div>
            <span className="non-valid">{formError.thumbnail_image}</span>
          </div>
          <div className="my-3 col-md-6">
            <Label for="imagefile">
              Select Case Studies Pdf (Upload only Pdf upto 5mb)
            </Label>
            <div className="file-field">
              <div className="btn helensys-theme-btn btn-sm ml-0">
                <span>Choose File</span>
                <Input
                  id="documentuplad"
                  name="pdffile"
                  type="file"
                  onChange={(event) =>
                    handleImageUpload(event, "thumbnail_pdf")
                  }
                  accept="image/pdf*"
                  encType="multipart/form-data"
                />
              </div>
              <div className="file-path-wrapper">
                <Input
                  className="file-path validate"
                  value={
                    modalData
                      ? modalData.thumbnail_pdf_name
                      : addCase.thumbnail_pdf_name
                  }
                  type="text"
                  placeholder=""
                  onChange={(event) =>
                    handleInputChange(event, "thumbnail_pdf_name")
                  }
                />
              </div>
            </div>
            <span className="non-valid">{formError.thumbnail_pdf}</span>
          </div>
          <div className="my-3 email">
            <Label for="email-confi">Email Configuration</Label>
            <Input
              type="checkbox"
              id="email-confi"
              name="email-confi"
              value="email-confi"
              checked={
                modalData?.emailconfigurationflag ||
                addCase?.emailconfigurationflag
              }
              onChange={() => {
                if (modalData) {
                  setModalData((prevModalData) => ({
                    ...prevModalData,
                    emailconfigurationflag:
                      !prevModalData.emailconfigurationflag,
                  }));
                } else {
                  setCase((prevAddCase) => ({
                    ...prevAddCase,
                    emailconfigurationflag: !prevAddCase.emailconfigurationflag,
                  }));
                }
              }}
            />
          </div>
          {/* Render email-show block */}
          {(modalData?.emailconfigurationflag ||
            addCase?.emailconfigurationflag) && (
            <div className="email-show">
              <div className="my-3 col-md-12">
                <Label for="url">Email send schedule in days</Label>
                <Input
                  type="number"
                  id="alttext"
                  name="email schedule"
                  value={getInputValue("sendemaildays")}
                  onChange={(event) =>
                    handleInputChange(event, "sendemaildays")
                  }
                />
              </div>
              <div className="my-3 col-md-12">
                <Label for="url">Email Template</Label>
                <JoditEditor
                  ref={editor}
                  config={editorConfig}
                  value={modalData?.emailtemplate || ""}
                  // onChange={(newContent) =>
                  //   handleInputChange(
                  //     { target: { value: newContent } },
                  //     "emailtemplate"
                  //   )
                  // }
                  onChange={(event) => contentDataChanged(event, "emailtemplate")}
                />
              </div>
            </div>
          )}

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning ms-2"
              onClick={handleClear}
            >
              Clear
            </button>
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-primary ms-2"
            >
              Submit
            </button>
          </div>
        </div>
      </Form>
    </>
  );
};
export default AddForm;
