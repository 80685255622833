import React from "react";
import { Card, CardBody, CardText } from "reactstrap";
import {
  FaCalendarAlt,
  FaUserEdit,
  FaFolder,
  FaGlobeAmericas,
  FaLanguage,
  FaRegImage,
  FaLink,
  FaWindowClose,
} from "react-icons/fa";

function ViewBlog({ Modalclose, modalData }) {
  if (!modalData) {
    return null; // or return some default content if modalData is null or undefined
  }

  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>BLOG DETAILS</h1>
          <div className="close-icon">
            <FaWindowClose onClick={Modalclose} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <section className="view-blog view-sec">
                <div className="container">
                  <h3>{modalData.name}</h3>
                  <div className="image-container mt-3 ">
                    {modalData.thumbnail_image && (
                      <img
                        src={`${modalData.thumbnail_image}?${Date.now()}`}
                        alt="Thumbnail"
                      />
                    )}
                  </div>
                  <div className="meta-tag mt-3">
                    <CardText>
                      <FaCalendarAlt />
                      <b>
                        {modalData.updatedDate ? (
                          <>{modalData.updatedDate}</>
                        ) : (
                          <>{modalData.createdDate}</>
                        )}
                      </b>
                    </CardText>

                    <CardText>
                      <FaUserEdit /> <b>{modalData.author}</b>
                    </CardText>
                    <CardText>
                      <FaFolder /> <b>{modalData.category}</b>
                    </CardText>
                    <CardText>
                      <FaGlobeAmericas /> <b>{modalData.country}</b>
                    </CardText>
                    <CardText>
                      <FaLanguage /> <b>{modalData.language}</b>
                    </CardText>
                    <CardText>
                      <FaRegImage /> <b>{modalData.imgalttext}</b>
                    </CardText>
                    <CardText>
                      <FaLink /> <b>{modalData.url}</b>
                    </CardText>
                  </div>
                  {modalData.short_Description && (
                    <>
 <label className="heading">Short Discription</label>
                  <div className="short-desc">
                    <div className="description mt-3">
                       {modalData.short_Description}
                    </div>
                  </div> 
                  </>
                   )}
                  {modalData.description && (
                    <div className="description mt-3">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: modalData.description,
                        }}
                      />
                    </div>
                  )}

                  <label className="heading">Status</label>
                  <div className="status-details">
                    <span
                      className={`status ${
                        modalData.status ? "active" : "inactive"
                      }`}
                    >
                      {modalData.status ? "active" : "inactive"}
                    </span>
                  </div>
                </div>
              </section>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default ViewBlog;
