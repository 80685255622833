import React from 'react'

/* ParseDate function  */

export const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return new Date(`${year}-${month}-${day}`);
  };

 /* Special Character function */ 

 export const isSpecialCharacter = (char) => {
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
    return specialCharacterRegex.test(char);
  }