import React, { useState, useEffect, useContext } from "react";
import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";
import { ApiContext } from "../apiContext";
import AddForm from "./AddForm";
import { getToken } from "../../auth/AuthService";
import axios from "axios";
import notify from "../../helper/Notify";
import { useLoader } from "../../helper/Loader";
import { isSpecialCharacter } from "../../helper/CommonFunction";

const Service = ({ closeModal, modalData, setModalData }) => {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);
  const { data, error, setData } = useContext(ApiContext);
  const [formError, setFormError] = useState({});
  const getData = data.casestudies.map((item) => item);
  const apiBaseUrl = window.API_CONFIG.All_Api.casestudies;
  const [file, setFile] = useState(null);
  const fieldsArray = ["description", "emailtemplate", "outcome", "challenges"];
  const token = getToken();
  const {setIsLoader} = useLoader();
  const [contentError, setContentError] = useState(null);
  const [dataSave, setDataSave] = useState({})
  const [addCase, setCase] = useState({
    title: "",
    url: "",
    companyname: "",
    alttext: "",
    thumbnail_image: "",
    usecase: "",
    industry: "",
    region: "",
    shortdescription: "",
    thumbnail_pdf: "",
    category: 0,
  });
  /*---- get all the input field ----*/
  const getInputValue = (field) => {
    return modalData ? modalData[field] : addCase[field];
  };
  /*---- change input field function ----*/
  const handleInputChange = (event, field) => {
    const { value } = event.target;
    console.log(value)
    if (modalData) {
      setModalData((prevModalData) => ({
        ...prevModalData,
        [field]: value,
      }));
    } else {
      setCase((prevAddCase) => ({
        ...prevAddCase,
        [field]: value,
      }));
    }
  };
 // For Jodit Editot use sep. datachange function
 const contentDataChanged = (data, field) => {
  if (data.includes("http:")) {
    setContentError("Please use secure 'https' URLs only");
    return;
  }
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = data;
  const h1Count = tempDiv.getElementsByTagName("h1").length;
  if (h1Count > 1) {
    setContentError("Only one <h1> tag is permitted.");
    return;
  }

  setContentError(null);

    if (modalData) {
      setDataSave((prevDataSave) => ({
        ...prevDataSave,
        [field]: data,
      }));
    } else {
    setCase((prevAddCase) => ({
      ...prevAddCase,
      [field]: data,
    }));
  }
};

  /* -----handleImage Upload function ----*/

  const handleImageUpload = async (event, field) => {
    const selectedFile = event.target.files[0];
    const selectfield = event.target.name;
    const fileSizeLimitImage = 100 * 1024; // 100KB in bytes
    const fileSizeLimitPDF = 5 * 1024 * 1024; // 5MB in bytes
    let fileSizeLimit;
    if (selectfield === "imagefile") {
      fileSizeLimit = fileSizeLimitImage;
    } else if (selectfield === "pdffile") {
      fileSizeLimit = fileSizeLimitPDF;
    } else {
      // Handle case when the target element name is neither 'imagefile' nor 'pdffile'
      return;
    }
    if (selectedFile.size > fileSizeLimit) {
      notify.error("File is too big");
      return;
    }
    try {
      const base64Data = await convertBase64(selectedFile);
      setFile(selectedFile);
      if (modalData) {
        setModalData({
          ...modalData,
          [`${field}_name`]: selectedFile.name,
          [field]: base64Data,
        });
      } else {
        setCase({
          ...addCase,
          [`${field}_name`]: selectedFile.name,
          [field]: base64Data,
        });
      }
    } catch (error) {
      console.log(error);
      // Handle error while converting to base64
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  /*---- handle clear function----*/
  const handleClear = () => {
    const clearfields = {
      title: "",
      url: "",
      companyname: "",
      alttext: "",
      thumbnail_image: "",
      usecase: "",
      industry: "",
      region: "",
      shortdescription: "",
      category: 0,
      description: "",
      emailtemplate: "",
      emailconfigurationflag: false,
      sendemaildays: "",
      outcome: "",
      challenges: "",
      thumbnail_image_name: "",
      thumbnail_pdf_name: "",
      file: null,
      status: false,
    };
    if (!modalData) {
      setCase(clearfields);
    } else {
      setModalData((prevModalData) => ({
        ...prevModalData,
        ...clearfields,
      }));
    }
  };

  /*---- validation on form fields----*/
  const validateFormFields = () => {
    const errors = {};
    const fieldarr = [
      "title",
      "url",
      "companyname",
      "alttext",
      "region",
      "usecase",
      "industry",
      "category",
      "thumbnail_image",
      "thumbnail_pdf",
    ];

    fieldarr.forEach((field) => {
      if (!modalData) {
        if (addCase && addCase[field] === "") {
          errors[field] = `${
            field.charAt(0).toUpperCase() + field.slice(1)
          } is required.`;
        }
      } else {
        if (modalData[field] === "") {
          errors[field] = `${
            field.charAt(0).toUpperCase() + field.slice(1)
          } is required.`;
        }
      }

      if (field === "category" && modalData && modalData[field] === 0) {
        errors[field] = "Category must be selected.";
      }
      if (
        field === "category" &&
        !modalData &&
        addCase &&
        addCase[field] === 0
      ) {
        errors[field] = "Category must be selected.";
      }
    });
    if (
      (!modalData || modalData.thumbnail_image === "") &&
      (!addCase || addCase.thumbnail_image === "")
    ) {
      errors.thumbnail_image = "Please select a file.";
    }

    if (
      (!modalData || modalData.thumbnail_pdf === "") &&
      (!addCase || addCase.thumbnail_pdf === "")
    ) {
      errors.thumbnail_pdf = "Please select a file.";
    }
    return errors;
  };

  async function processAndUploadImages(modalData, addCase, fieldsArray) {
    for (const field of fieldsArray) {
      const htmlContent = modalData ? modalData[field] : addCase[field];
      if (!htmlContent) {
        // Handle the case where htmlContent is undefined or empty
        continue; // Skip to the next field
      }
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = htmlContent;
      const imgElements = Array.from(tempDiv.querySelectorAll("img"));

      const base64ToBlob = (base64, mimeType) => {
        const byteCharacters = atob(base64);
        const byteArray = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteArray[i] = byteCharacters.charCodeAt(i);
        }
        return new Blob([byteArray], { type: mimeType });
      };

      const processImage = async (img) => {
        const src = img.getAttribute("src");
        if (src && src.startsWith("data:image/")) {
          const parts = src.split(";");
          const mimeType = parts[0].split(":")[1];
          const imageData = parts[1].split(",")[1];
          const blob = base64ToBlob(imageData, mimeType);
          const file = new File([blob], "image.png", { type: mimeType });

          const formData = new FormData();
          formData.append("upload", file);
          formData.append(
            "ckCsrfToken",
            "2fDpPNU1D4C9z19x1f0XZTAaH6nZlR8DoiH950V5"
          );

          try {
            const response = await fetch(window.API_CONFIG.imageStringUrl, {
              method: "POST",
              body: formData,
            });

            if (response.ok) {
              const data = await response.json();
              const imgUrl = data.url;
              img.setAttribute("src", imgUrl);
            } else {
              console.error("Error uploading image:", response.statusText);
            }
          } catch (error) {
            console.error("Error processing image:", error);
          }
        }
      };

      const processImagesSequentially = async () => {
        for (const img of imgElements) {
          await processImage(img);
        }
        if (modalData) {
          modalData[field] = tempDiv.innerHTML;
        } else {
          addCase[field] = tempDiv.innerHTML;
        }
      };

      await processImagesSequentially();
    }
  }
  
  /* form submit function*/
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formErrors = validateFormFields();
    if (Object.keys(formErrors).length > 0) {
      setFormError({ ...formErrors });
      notify.warning();
      return;
    }
  setFormError(""); 
  setIsLoader(true);
    try {
      if (modalData) {
        const updatedModalData = { ...modalData, ...dataSave };
        await processAndUploadImages(updatedModalData, null, fieldsArray);
        const updatedData = {
          ...updatedModalData,
          updatedDate: new Date().toISOString().slice(0, 10),
        };
        const duplicateItem = getData.find(
          (item) => item.title === modalData.title && item.id !== modalData.id // Make sure it's not the same item being updated
        );

        if (duplicateItem) {
          setIsLoader(false);
          notify.error("Data is a duplicate and cannot be updated.");
          return;
        }

        if(contentError){
          notify.error(contentError);
          setIsLoader(false);
          return;
        }

        if (isSpecialCharacter(modalData.title.charAt(0))){
          setIsLoader(false);
          notify.error("Name should not start with special character");
             return;
        }

          const response = await axios.post(apiBaseUrl, updatedData, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "x-access-token": token,
            },
          });
          if (response.data.success === true){
          const updatedDataResponse = await axios.get(apiBaseUrl, {
            headers: {
              "x-access-token": token,
            },
          });
          const updatedDataFromServer =
            updatedDataResponse.data.data?.ViewModels || [];

          setData((prevData) => ({
            ...prevData,
            casestudies: updatedDataFromServer,
          }));

          closeModal();

          notify.success(response.data.message);
          setCase({});
          setIsLoader(false);
        }
          else{
            setIsLoader(false);
            notify.error(response.data.message)
          }
        } 
     else {
        // Adding a new data entry
        if (file) {
            await processAndUploadImages(null, addCase, fieldsArray);
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = async () => {
              const base64Data = reader.result.split(",")[1];

              const updatedBlogData = {
                ...addCase,
                createdDate: new Date().toISOString().slice(0, 10),
                thumbnail_pdf: file.type.includes("application/pdf")
                  ? base64Data
                  : addCase.thumbnail_pdf,
              };
              const duplicateItem = getData.find(
                (item) => item.title === addCase.title
              );

              if (duplicateItem) {
                setIsLoader(false);
                notify.error("Data is a duplicate and cannot be updated.");
                return;
              }

              if(contentError){
                notify.error(contentError);
                setIsLoader(false);
                return;
              }

              if (isSpecialCharacter(addCase.title.charAt(0))){
                notify.error("Name should not start with special character");
                setIsLoader(false);
                   return;
              }
            

                const response = await axios.post(apiBaseUrl, updatedBlogData, {
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "x-access-token": token,
                  },
                });

               if (response.data.success === true){
                const updatedBlogResponse = await axios.get(apiBaseUrl, {
                  headers: {
                    "x-access-token": token,
                  },
                });
                const updatedDataFromServer =
                  updatedBlogResponse.data.data?.ViewModels || [];
                setData((prevData) => ({
                  ...prevData,
                  casestudies: updatedDataFromServer,
                }));
                closeModal();
                notify.success(response.data.message);
                setCase({});
                setIsLoader(false);
              }
              else {
                setIsLoader(false);
                notify.error(response.data.message);
              }
            };

            reader.onerror = (error) => {
              setIsLoader(false);
              console.error("FileReader error:", error);
              // Handle FileReader error
            };
        }
      }
    } catch (error) {
      console.error("Error occurred:", error);
      setIsLoader(false);
      notify.error(error.message);
    }
   
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>{modalData ? "Edit CaseStudies" : "Add New CaseStudies"}</h1>
          <div className="close-icon">
            <FaWindowClose onClick={closeModal} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <AddForm
                handleSubmit={handleSubmit}
                formError={formError}
                getInputValue={getInputValue}
                handleInputChange={handleInputChange}
                contentDataChanged = {contentDataChanged}
                handleImageUpload={handleImageUpload}
                handleClear={handleClear}
                modalData={modalData}
                addCase={addCase}
                setModalData={setModalData}
                setCase={setCase}
                caseStudiesCategory={data.caseStudiesCategory}
                closeModal={closeModal}
                thumbnail_pdf_name={getInputValue("thumbnail_pdf_name")}
                thumbnail_image_name={getInputValue("thumbnail_image_name")}
              />
            </CardBody>
            <div className="avc"></div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Service;
