import React, { useRef, useContext, useState, useEffect } from "react";
import { Form, Label, Input } from "reactstrap";
import JoditEditor from "jodit-react";
import { ApiContext } from "../apiContext";
import EditorConfig from "../../helper/JoditEditor";

export default function AddForm({
  handleSubmit,
  formError,
  getInputValue,
  handleInputChange,
  contentDataChanged,
  handleImageUpload,
  handleClear,
  modalData,
  addCase,
  setModalData,
  setCase,
  closeModal,
  thumbnail_image_name,
}) {
  const editor = useRef(null);
  const [editorConfig, setEditorConfig] = useState(null);
  useEffect(() => {
    // Initialize Jodit editor
    setEditorConfig(EditorConfig);
  }, []);
  const { error } = useContext(ApiContext);
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="row edit-form">
          <div className="my-3 col-md-6">
            <Label htmlFor="url">Title</Label>
            <Input
              type="text"
              id="title"
              name="title"
              placeholder="Title"
              value={getInputValue("title")}
              onChange={(event) => handleInputChange(event, "title")}
            />
            <span className="non-valid">{formError.title}</span>
          </div>

          <div className="my-3 col-md-6">
            <Label htmlFor="location">Location</Label>
            <Input
              type="text"
              id="location"
              name="location"
              placeholder="location"
              value={getInputValue("location")}
              onChange={(event) => handleInputChange(event, "location")}
            />
             <span className="non-valid">{formError.location}</span>
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="location">Start Data</Label>
            <Input
              type="date"
              id="startdate"
              name="startdate"
              value={getInputValue("startdate")}
              onChange={(event) => handleInputChange(event, "startdate")}
            />
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="location">Start Time</Label>
            <Input
              type="time"
              format="mm:ssTZD"
              id="starttime"
              name="starttime"
              placeholder="starttime"
              value={getInputValue("starttime")}
              onChange={(event) => handleInputChange(event, "starttime")}
            />
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="location">End Date</Label>
            <Input
              type="date"
              id="enddate"
              name="enddate"
              placeholder="enddate"
              value={getInputValue("enddate")}
              onChange={(event) => handleInputChange(event, "enddate")}
            />
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="location">End Time</Label>
            <Input
              type="time"
              format="mm:ssTZD"
              id="endtime"
              name="endtime"
              placeholder="endtime"
              value={getInputValue("endtime")}
              onChange={(event) => handleInputChange(event, "endtime")}
            />
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="location">Event</Label>
            <div className="toggle">
              <span>External</span>
              <label
                className={`switch ${
                  (modalData && modalData.eEvent) || addCase.eEvent
                    ? "true"
                    : ""
                }`}
              >
                <Input
                  type="checkbox"
                  checked={modalData && modalData.eEvent}
                  onChange={() => {
                    if (modalData) {
                      setModalData((prevModalData) => ({
                        ...prevModalData,
                        eEvent: !prevModalData.eEvent,
                      }));
                    } else {
                      setCase((prevAddCase) => ({
                        ...prevAddCase,
                        eEvent: !prevAddCase.eEvent,
                      }));
                    }
                  }}
                />
                <span className="slider round"></span>
              </label>
              <span>Internal</span>
            </div>
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="invitation">Invitation Link</Label>
            <Input
              type="text"
              id="invitation"
              name="invitation"
              placeholder="invitation"
              value={getInputValue("invitation")}
              onChange={(event) => handleInputChange(event, "invitation")}
            />
          </div>
          <div className="my-3 col-md-12">
            <Label htmlFor="author-bio">Description</Label>
            <JoditEditor
              ref={editor}
              config={editorConfig}
              tabIndex={1}
              value={modalData?.description || ""}
              // onBlur={(newContent) =>
              //   handleInputChange(
              //     { target: { value: newContent } },
              //     "description"
              //   )
              // }
              onChange={(event) => contentDataChanged(event, "description")}
            />
          </div>
          <div className="my-3 col-md-12">
            <Label htmlFor="ShortDescription">Short Description</Label>
            <textarea
              type="text"
              id="ShortDescription"
              name="ShortDescription"
              placeholder="Short Description"
              value={getInputValue("ShortDescription")}
              onChange={(event) => handleInputChange(event, "ShortDescription")}
            />
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="imagefile">
              Select Event Image (Upload only images up to 100kb)
            </Label>
            <div className="file-field">
              <div className="btn helensys-theme-btn btn-sm ml-0">
                <span>Choose File</span>
                <Input
                  id="documentuplad"
                  name="imagefile"
                  type="file"
                  onChange={(event) =>
                    handleImageUpload(event, "thumbnail_image")
                  }
                  accept="image/gif, image/jpeg, image/png, image/webp*"
                  encType="multipart/form-data"
                />
              </div>
              <div className="file-path-wrapper">
                <Input
                  className="file-path validate"
                  value={
                    modalData
                      ? modalData.thumbnail_image_name
                      : addCase.thumbnail_image_name
                  }
                  type="text"
                  placeholder=""
                  onChange={(event) =>
                    handleInputChange(event, "thumbnail_image_name")
                  }
                />
              </div>
            </div>
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="status">Status</Label>
            <div className="toggle">
              <span>Inactive</span>
              <label
                className={`switch ${
                  (modalData && modalData.status) || addCase.status
                    ? "true"
                    : ""
                }`}
              >
                <Input
                  type="checkbox"
                  checked={modalData && modalData.status}
                  onChange={() => {
                    if (modalData) {
                      setModalData((prevModalData) => ({
                        ...prevModalData,
                        status: !prevModalData.status,
                      }));
                    } else {
                      setCase((prevAddCase) => ({
                        ...prevAddCase,
                        status: !prevAddCase.status,
                      }));
                    }
                  }}
                />
                <span className="slider round"></span>
              </label>
              <span>Active</span>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning ms-2"
              onClick={handleClear}
            >
              Clear
            </button>
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-primary ms-2"
            >
              Submit
            </button>
          </div>
        </div>
      </Form>
    </>
  );
}
