import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../apiContext";
import ViewForm from "./ViewForm";
import { FaEye, FaFilePdf } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6";
import PdfModal from "./PdfModal";
import notify from "../../helper/Notify";

export function LeadsApi({ page = 1, pageSize, searchQuery, totalLength, selectedCategory, selectStatus, emailType }) {
  const { data, error } = useContext(ApiContext);
  const [modalData, setModalData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [filteredLeads, setFilteredLeads] = useState([]);

  const closeModal = () => setShowModal(false);
  const modalClose = () => setPdfModal(false);

  useEffect(() => {
    try {
      if (!data.leads || data.leads.length === 0) {
        throw new Error("No data available");
      }

      let filteredData = data.leads;

      filteredData = filteredData.sort((a, b) => {
        return new Date(b.dateTime) - new Date(a.dateTime);
      });

      if (selectedCategory === "Ham Emails") {
        filteredData = filteredData.filter(lead => lead.classification === "ham");
      } else if (selectedCategory === "Spam Emails") {
        filteredData = filteredData.filter(lead => lead.classification === "spam");
      }
      if (selectStatus === "Pending") {
        filteredData = filteredData.filter(lead => lead.mail_send_flag === "0");
      } else if (selectStatus === "Send") {
        filteredData = filteredData.filter(lead => lead.mail_send_flag !== "0");
      }
      if (emailType === "Subscribe") {
        filteredData = filteredData.filter(lead => lead.mailType === "subscribe");
      } else if (emailType === "Callback") {
        filteredData = filteredData.filter(lead => lead.mailType === "callback");
      } else if (emailType === "Free Trail Demo") {
        filteredData = filteredData.filter(lead => lead.mailType === "freeTrailDemo");
      } else if (emailType === "Demo") {
        filteredData = filteredData.filter(lead => lead.mailType === "demo");
      } else if (emailType === "Ebook") {
        filteredData = filteredData.filter(lead => lead.mailType === "ebookemail");
      } else if (emailType === "Casestudy") {
        filteredData = filteredData.filter(lead => lead.mailType === "casestudy");
      } else if (emailType === "Career") {
        filteredData = filteredData.filter(lead => lead.mailType === "career");
      }

      if (searchQuery) {
        const searchLower = searchQuery.toLowerCase();
        filteredData = filteredData.filter(lead =>
          (lead.fullname && lead.fullname.toLowerCase().includes(searchLower)) ||
          (lead.Email && lead.Email.toLowerCase().includes(searchLower)) ||
          (lead.PhoneNumber && lead.PhoneNumber.toLowerCase().includes(searchLower)) ||
          (lead.dateTime && lead.dateTime.toLowerCase().includes(searchLower))
        );
      }

      setFilteredLeads(filteredData);
      totalLength(filteredData.length);
    } catch (error) {
      setFilteredLeads([]);
      totalLength(0);
      console.error("Error fetching or processing data:", error);
    }
  }, [data.leads, searchQuery, page, pageSize, totalLength, selectedCategory]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedLeads = filteredLeads.slice(startIndex, endIndex);

    // const checkPdf = () =>{
    //   return modalData && modalData.fileName.toLowerCase().endsWith('.pdf');
    // }
    const pdfOpen = (lead) => {
      // Check if the fileName ends with '.pdf'
      if (lead.fileName.endsWith('.pdf')) {
        setPdfModal(true);
        setModalData(lead);
         
      } else {
        notify.error("We are currently unable to display this file extension. We're working on supporting it, and it will be available soon.");
      }
  };

    return (
      <>
        <tbody>
          {filteredLeads.length === 0 ? (
            <tr>
              <td className="noFound" colSpan="12">No data found</td>
            </tr>
          ) : (
            paginatedLeads.map((lead, index) => {
              const id = `item-${index}`;

              return (
                <tr key={id}>
                  <td
                    title="View"
                    onClick={() => {
                      setShowModal(true);
                      setModalData(lead);
                    }}
                  >
                    <FaEye />
                  </td>

                  {lead.fileName === null || lead.fileName === '' ? (
                    <td>
                      <FaCircleXmark />
                    </td>
                  ) : lead.mailType === "career" ? (
                    <td
                      title="Pdf"
                      onClick={() => pdfOpen(lead)}
                    >
                      <FaFilePdf />
                    </td>
                  ) : (
                    <td>
                      <FaCircleXmark />
                    </td>
                  )}

                  <td title={lead.fullname}>{lead.fullname && lead.fullname.length > 20
                    ? `${lead.fullname.substring(0,20)}...`
                  :lead.fullname}</td>
                  <td title={lead.classification}>{lead.classification}</td>
                  <td title={lead.Email}>{lead.Email && lead.Email.length > 25
                    ? `${lead.Email.substring(0, 25)}...` 
                    :lead.Email}</td>
                  <td title={lead.PhoneNumber}>
                    {lead.PhoneNumber && lead.PhoneNumber.length > 12
                    ? `${lead.PhoneNumber.substring(0, 12)}...` 
                    : lead.PhoneNumber}</td>
                  <td title={lead.subject}>
                    {lead.subject && lead.subject.length > 30
                      ? `${lead.subject.substring(0, 30)}...`
                      : lead.subject}
                  </td>

                  <td title={lead.dateTime}>
                    {lead && lead.dateTime ? lead.dateTime : "Not available"}
                  </td>
                  <td>
                    <span className={`status ${lead.mail_send_flag !== "0" ? "active" : "inactive"}`}>
                      {lead.mail_send_flag !== "0" ? "Send" : "Pending"}
                    </span>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
         
        {showModal && (
          <ViewForm Modalclose={closeModal} modalData={modalData} />
        )}
        {pdfModal && (
          <PdfModal closeModal={modalClose} modalData={modalData} />
        )}
      </>
    );
  }
}

export const LeadLength = () => {
  const { data } = useContext(ApiContext);
  return data.leads.length;
};

export default LeadsApi;
