import React from "react";
import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";

export default function ViewForm({ Modalclose, modalData }) {
  if (!modalData) {
    return null;
  }
  const formattedProduct = Array.isArray(modalData.product)
  ? modalData.product.join(", ")
  : modalData.product;

  const heading = modalData.mailType.charAt(0).toUpperCase() + modalData.mailType.slice(1);

  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>Lead Details</h1>
          <div className="close-icon">
            <FaWindowClose onClick={Modalclose} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <section className="view-blog view-sec">
                <div className="container">
                  <h3 style={{color:"#526cfe"}}>{heading}</h3>
                  <h6 style={{color:"#526cfe"}}>Request Deatils :</h6>
                  <div className="content">
                    {modalData.fullname &&(
                    <div className="data-fields">
                    <label className="heading">Name :</label>
                    <p>{modalData.fullname}</p>
                    </div>
                    )}
                   {modalData.PhoneNumber &&(
                    <div className="data-fields">
                    <label className="heading">Contact Number :</label>
                    <p>{modalData.PhoneNumber}</p>
                    </div>
                    )}
                    {modalData.Email &&(
                    <div className="data-fields">
                    <label className="heading">Email :</label>
                    <p>{modalData.Email}</p>
                    </div>
                    )}
                    {modalData.Country &&(
                    <div className="data-fields">
                    <label className="heading">Country :</label>
                    <p>{modalData.Country}</p>
                    </div>
                    )}
                     {modalData.Company &&(
                    <div className="data-fields">
                    <label className="heading">Company :</label>
                    <p>{modalData.Company}</p>
                    </div>
                    )}
                   {modalData.classification &&(
                    <div className="data-fields">
                    <label className="heading">Category :</label>
                    <p>{modalData.classification}</p>
                    </div>
                    )}

                     {modalData.CompanySize &&(
                    <div className="data-fields">
                    <label className="heading">Company Size :</label>
                    <p>{modalData.CompanySize}</p>
                    </div>
                    )}

                     {formattedProduct &&(
                    <div className="data-fields">
                    <label className="heading">Interested Product :</label>
                    <p>{formattedProduct}</p>
                    </div>
                    )}
                    {modalData.subject &&(
                    <div className="data-fields">
                    <label className="heading">Subject :</label>
                    <p>{modalData.subject}</p>
                    </div>
                    )}  
                    {modalData.needs &&(
                    <div className="data-fields">
                    <label className="heading">Needs :</label>
                    <p>{modalData.needs}</p>
                    </div>
                    )}
                                  
               {modalData.applyingFor &&(
                    <div className="data-fields">
                    <label className="heading">Apply for :</label>
                    <p>{modalData.applyingFor}</p>
                    </div>
                    )}
                    {modalData.experience &&(
                    <div className="data-fields">
                    <label className="heading">Experience :</label>
                    <p>{modalData.experience}</p>
                    </div>
                    )}
{/*  
                    {modalData.fileName &&(
                    <div className="data-fields">
                    <label className="heading">File Name :</label>
                    <p>{modalData.fileName}</p>
                    </div>
                    )} */}
                   
                    {modalData.dateTime &&(
                    <div className="data-fields">
                    <label className="heading">Date :</label>
                    <p>{modalData.dateTime}</p>
                    </div>
                    )}
                    <div className="data-fields">
                    <label className="heading">Status :</label>
                    <span className={`status ${modalData.mail_send_flag !== "0" ? "active" : "inactive"}`}>
                      {modalData.mail_send_flag !==   "0" ? "Send" : "Pending"}
                    </span>
                    </div>
                    
                  </div>
                </div>
              </section>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
