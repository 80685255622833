import React from "react";
import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";
export default function PdfModal({ closeModal, modalData }) {


      if (!modalData) {
        return null;
    }
  
    return (
        <>
            <div className="modal-wrapper pdf"></div>
            <div className="modal-container">
                <div className="modal-head">
                    <h1>Resume</h1>
                    <div className="close-icon">
                        <FaWindowClose onClick={closeModal} />
                    </div>
                </div>
                <div className="modal-body pdf-view">
                    <Card>
                        <CardBody>
                            <section className="view-blog view-sec">
                                 <iframe
                                            title="Resume PDF"
                                            src={modalData.fileName}
                                            width="100%"
                                            height="430px"
                                            
                                            style={{ border: 'none' }}
                                        />
                                        
                            </section>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </>
    );
}
