import React, { useContext, useState, useEffect } from "react";
import { ApiContext } from "../apiContext";
import { FaEye, FaEdit } from "react-icons/fa";
import Service from "./Service";
import ViewForm from "./ViewForm";
// import { getToken } from "../../auth/AuthService";
// import { useLoader } from "../../helper/Loader";

function MetaTagApi({ page = 1, pageSize, searchQuery, totalLength }) {
  const { data, error } = useContext(ApiContext);
  const [filteredBlog, setFilteredBlog] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  // const apiBaseUrl = window.API_CONFIG.All_Api.metaTag;

  const closeModal = () => setShowModal(false);
  const modalClose = () => setEditModal(false);
  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [deleteCandidate, setDeleteCandidate] = useState(null);

  useEffect(() => {
    try {
      if (!data.metaTag || data.metaTag.length === 0) {
        throw new Error("No data available");
      }

      const filteredData = data.metaTag.filter((item) => {
        const name = item?.title ?? "";
        return name.toLowerCase().includes(searchQuery.toLowerCase());
      });

      const sortedData = filteredData.sort(
        (a, b) =>
          {
            const dateA = a.updatedDate ? new Date(a.updatedDate) : new Date(a.createdDate);
            const dateB = b.updatedDate ? new Date(b.updatedDate) : new Date(b.createdDate);
            return dateB - dateA; 
      }
      );

      setFilteredBlog(sortedData);
      totalLength(filteredData.length);
    } catch (error) {
      console.error("Error fetching or processing data:", error);
    }
  }, [data.metaTag, searchQuery, page, pageSize, totalLength]);

  /* Handle Remove function */

  // const closeDelModal = () => setShowDeleteModal(false);

  // const performDelete = async () => {
  //   closeDelModal(); 
  //   setIsLoader(true);
  //   try {
  //     const updatedCareerData = data.metaTag.filter(
  //       (item) => item.code !== deleteCandidate.code
  //     );

  //     const response = await axios.post(
  //       apiBaseUrl,
  //       { ViewModels: updatedCareerData },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           "x-access-token": token,
  //         },
  //       }
  //     );
  //     console.log(response);
  //     setData((prevData) => ({
  //       ...prevData,
  //       metaTag: updatedCareerData,
  //     }));

  //     notify.success("Data removed successfully");
  //   } catch (error) {
  //     console.error("Error removing data:", error);
  //     notify.error(error.message);
  //   }
  //   setIsLoader(false);
  // };

  // const handleRemove = (dataToRemove) => {
  //   setShowDeleteModal(true);
  //   setDeleteCandidate(dataToRemove);
  // };
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const items = filteredBlog
    .slice(startIndex, endIndex)
    .map((viewModel, index) => {
      const id = `item-${index}`;
      const fetchData = async () => {
        try {
          setModalData(viewModel);
        } catch (error) {
          console.error(error);
        }
      };
      return (
        <tr key={id}>
          <td
            title="View"
            onClick={() => {
              setShowModal(true);
              fetchData();
            }}
          >
            <FaEye />
          </td>
          <td
            title="Edit"
            onClick={() => {
              setEditModal(true);
              fetchData();
            }}
          >
            <FaEdit />
          </td>
          <td width="20%">
            {viewModel && viewModel.url
              ? `${viewModel.url.substring(0, 30)}...`
              : "Url not available"}
          </td>
          <td width="20%">
            {viewModel && viewModel.title
              ? `${viewModel.title.substring(0, 30)}...`
              : "Title not available"}
          </td>
          <td width="20%">
            {viewModel && viewModel.metaDescription
              ? `${viewModel.metaDescription.substring(0, 30)}...`
              : "Description not available"}
          </td>
          <td width="20%">
            {viewModel && viewModel.metaKeywords
              ? `${viewModel.metaKeywords.substring(0, 30)}...`
              : "Meta Keywords not available"}
          </td>
          <td width="30%">
            {viewModel && viewModel.metaCanonical
              ? `${viewModel.metaCanonical.substring(0, 30)}`
              : "Meta Canonicalnot available"}
          </td>
          <td width="10%">
              <span
                className={`status ${viewModel.status ? "active" : "inactive"}`}
              >
                {viewModel.status ? "active" : "inactive"}
              </span>
            </td>
          {showModal && (
            <ViewForm Modalclose={closeModal} modalData={modalData} />
          )}
          {editModal && (
            <Service
              closeModal={modalClose}
              modalData={modalData}
              setModalData={setModalData}
              setFilteredBlog={setFilteredBlog}
            />
          )}
        </tr>
      );
    });

  return (
    <tbody>
      {filteredBlog.length === 0 ? (
        <tr>
        <td classname ="noFound" colSpan="12">No data found</td>
        </tr>
      ) : (
        items
      )}
    </tbody>
  );
}
export const MetaTagLength = () => {
  const { data } = useContext(ApiContext);
  return data.metaTag.length;
};
export default MetaTagApi;
