import React, { useContext, useState, useEffect } from "react";
import { FaEye, FaEdit, FaTrashAlt } from "react-icons/fa";
import { ApiContext } from "../apiContext";
import Service from "./RedirectServ";
import Viewdata from "./ViewData";
import axios from "axios";
import { getToken } from "../../auth/AuthService";
import DeleteBox from "../DeleteBox";
import notify from "../../helper/Notify";
import { useLoader } from "../../helper/Loader";

function UrlApi({ page = 1, pageSize = 10, searchQuery, totalLength }) {
  const { data, setData, error } = useContext(ApiContext);
  const [filteredBlog, setFilteredBlog] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const token = getToken();
  const {setIsLoader} = useLoader();
  const apiBaseUrl = window.API_CONFIG.All_Api.urllist;
  const closeModal = () => setShowModal(false);
  const modalClose = () => setEditModal(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCandidate, setDeleteCandidate] = useState(null);
  useEffect(() => {
    try {
      if (!data.urllist || data.urllist.length === 0) {
        throw new Error("No data available");
      }

      const filteredData = data.urllist.filter((item) => {
        const name = item?.fromurl ?? "";
        return name.toLowerCase().includes(searchQuery.toLowerCase());
      });

      const sortedData = filteredData.sort(
        (a, b) =>{
          const dateA = a.updatedDate ? new Date(a.updatedDate) : new Date(a.createdDate);
          const dateB = b.updatedDate ? new Date(b.updatedDate) : new Date(b.createdDate);
          return dateB - dateA; 
    });

      setFilteredBlog(sortedData);
      totalLength(filteredData.length);
    } catch (error) {
      console.error("Error fetching or processing data:", error);
    }
  }, [data.urllist, searchQuery, page, pageSize, totalLength]);

  /* Handle Remove function */

  const closeDelModal = () => setShowDeleteModal(false);

  const performDelete = async () => {
    closeDelModal(); 
    setIsLoader(true);
    try {
      const updatedCareerData = data.urllist.filter(
        (item) => item.code !== deleteCandidate.code
      );

      const response = await axios.post(
        apiBaseUrl,
        { ViewModels: updatedCareerData },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-access-token": token,
          },
        }
      );
      console.log(response);

      setData((prevData) => ({
        ...prevData,
        urllist: updatedCareerData,
      }));

      notify.success("Data removed successfully");
    } catch (error) {
      console.error("Error removing data:", error);
      notify.error("Error removing data");
    }
    setIsLoader(false);
  };

  const handleRemove = (dataToRemove) => {
    setShowDeleteModal(true);
    setDeleteCandidate(dataToRemove);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const items = filteredBlog
      .slice(startIndex, endIndex)
      .map((viewModel, index) => {
        const id = `item-${index}`;
        const fetchData = async () => {
          try {
            setModalData(viewModel);
          } catch (error) {
            console.error(error);
          }
        };
        return (
          <tr key={id}>
            <td title="View"
              onClick={() => {
                setShowModal(true);
                fetchData();
              }}
            >
              <FaEye />
            </td>
            <td title="Edit"
              onClick={() => {
                setEditModal(true);
                fetchData();
              }}
            >
              <FaEdit />
            </td>
            <td  title ="Delete" onClick={() => handleRemove(viewModel)}>
              <FaTrashAlt />
            </td>
            <td title={viewModel.fromurl} width="25%">
            {viewModel.fromurl && viewModel.fromurl.length > 40
                ? `${viewModel.fromurl.substring(0, 40)}...`
                : viewModel.fromurl || "Url not available"}
                </td>
            <td title = {viewModel.tourl} width="35%"
            > {viewModel.tourl && viewModel.tourl.length > 40
                ? `${viewModel.tourl.substring(0, 40)}...`
                : viewModel.tourl || "Url not available"} </td>
            <td width="15%">
              {viewModel.updatedDate || viewModel.createdDate}
            </td>
            <td width="10%">
              <span
                className={`status ${viewModel.status ? "active" : "inactive"}`}
              >
                {viewModel.status ? "active" : "inactive"}
              </span>
            </td>
            {showModal && (
              <Viewdata Modalclose={closeModal} modalData={modalData} />
            )}
            {editModal && (
              <Service
                closeModal={modalClose}
                modalData={modalData}
                setModalData={setModalData}
                setFilteredBlog={setFilteredBlog}
              />
            )}
          </tr>
        );
      });

    return (
      <tbody>
        {filteredBlog.length === 0 ? (
          <tr>
          <td classname ="noFound" colSpan="12">No data found</td>
          </tr>
        ) : (
          items
        )}
        {showDeleteModal && (
          <DeleteBox Modalclose={closeDelModal} handleDelete={performDelete} />
        )}
      </tbody>
    );
  }
}

export const UrlLength = () => {
  const { data } = useContext(ApiContext);
  return data.urllist.length;
};

export default UrlApi;
