import React, { useState, useRef, useEffect, useContext } from "react";
import { Form, Label, Input } from "reactstrap";
import JoditEditor from "jodit-react";
import { ApiContext } from "../apiContext";
import EditorConfig from "../../helper/JoditEditor";

const AddForm = ({
  handleSubmit,
  formError,
  getInputValue,
  handleInputChange,
  contentDataChanged,
  handleClear,
  modalData,
  addCase,
  setModalData,
  setCase,
  closeModal,
}) => {
  const editor = useRef(null);
  const [editorConfig, setEditorConfig] = useState(null);
  useEffect(() => {
    // Initialize Jodit editor
    setEditorConfig(EditorConfig);
  }, []);
  const { error } = useContext(ApiContext);
  const [questionList, setQuestionList] = useState([""]);
  const [answerList, setAnswerList] = useState([""]);

  useEffect(() => {
    if (modalData?.question) {
      setQuestionList(
        Object.keys(modalData.question).map((key) => modalData.question[key])
      );
    } else if (Array.isArray(addCase.question)) {
      setQuestionList([...addCase.question]);
    } else if (typeof addCase.question === "object") {
      setQuestionList(Object.values(addCase.question));
    }

    if (modalData?.answers) {
      setAnswerList(
        Object.keys(modalData.answers).map((key) => modalData.answers[key])
      );
    } else if (Array.isArray(addCase.answers)) {
      setAnswerList([...addCase.answers]);
    } else if (typeof addCase.answers === "object") {
      setAnswerList(Object.values(addCase.answers));
    }
  }, [modalData, addCase]);

  const questions = modalData?.question || {};
  const answers = modalData?.answers || {};
  const addNewQuestion = () => {
    setQuestionList((prevQuestionList) => [...prevQuestionList, ""]);
    setAnswerList((prevAnswerList) => [...prevAnswerList, ""]);
  };

  const removeData = (index) => {
    setQuestionList((prevQuestionList) =>
      prevQuestionList.filter((_, i) => i !== index)
    );
    setAnswerList((prevAnswerList) =>
      prevAnswerList.filter((_, i) => i !== index)
    );
  
    if (modalData) {
      const updatedModalData = {
        ...modalData,
        question: Object.keys(questions).reduce((acc, key, i) => {
          if (i !== index) {
            acc[key] = questions[key];
          }
          return acc;
        }, {}),
        answers: Object.keys(answers).reduce((acc, key, i) => {
          if (i !== index) {
            acc[key] = answers[key];
          }
          return acc;
        }, {}),
      };
      setModalData(updatedModalData);
    } else {
      setCase((prevAddCase) => {
        const { question, answers, ...rest } = prevAddCase;
        return {
          ...rest,
          question: Object.keys(question).reduce((acc, key, i) => {
            if (i !== index) {
              acc[key] = question[key];
            }
            return acc;
          }, {}),
          answers: Object.keys(answers).reduce((acc, key, i) => {
            if (i !== index) {
              acc[key] = answers[key];
            }
            return acc;
          }, {}),
        };
      });
      console.log(setCase);
    }
  };
  
  

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="row edit-form">
          <div className="add-data row">
            <div className="my-3 col-md-10">
              <Label htmlFor="url">Page</Label>
              <Input
                type="text"
                id="page"
                name="page"
                placeholder="Page"
                value={getInputValue("page")}
                onChange={(event) => handleInputChange(event, "page")}
              />
              <span className="non-valid">{formError.page}</span>
            </div>

            <div className="add-btn col-md-2">
              <button
                type="button"
                className="new-blog"
                onClick={addNewQuestion}
              >
                +
              </button>
            </div>
          </div>
          {questionList.map((question, index) => (
            <div key={index}>
              <div className="my-3 col-md-12">
                <Label htmlFor={`question-${index}`}>
                  Question {index + 1}
                </Label>
                <div className="d-flex">
                  <Input
                    type="text"
                    id={`question-${index}`}
                    name={`question-${index}`}
                    placeholder="Question"
                    value={question}
                    onChange={(event) =>
                      handleInputChange(event, "question", index)
                    }
                  />
                  <span className="non-valid">{formError.question}</span>
                  {index > 0 && (
                    <button
                      type="button"
                      className="btn btn-danger ms-2"
                      onClick={() => removeData(index)}
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>
              <div className="my-3 col-md-12">
                <Label htmlFor={`answers-${index}`}>Answer {index + 1}</Label>
                <JoditEditor
                  ref={editor}
                  id={`answers-${index}`}
                  config={editorConfig}
                  name={`answers-${index}`}
                  value={answerList[index] || ""}
                  // onBlur={(newContent) =>
                  //   handleInputChange(
                  //     { target: { value: newContent } },
                  //     "answers",
                  //     index
                  //   )
                  // }
                  // onChange={(newContent) => {}}
                  onChange={(event) => contentDataChanged(event, "answers",index)}
                />
                <span className="non-valid">{formError.answers}</span>
              </div>
            </div>
          ))}

          <div className="my-3 col-md-6">
            <Label htmlFor="status">Status</Label>
            <div className="toggle">
              <span>Inactive</span>
              <label
                className={`switch ${
                  (modalData && modalData.status) || addCase.status
                    ? "true"
                    : ""
                }`}
              >
                <Input
                  type="checkbox"
                  checked={modalData && modalData.status}
                  onChange={() => {
                    if (modalData) {
                      setModalData((prevModalData) => ({
                        ...prevModalData,
                        status: !prevModalData.status,
                      }));
                    } else {
                      setCase((prevAddCase) => ({
                        ...prevAddCase,
                        status: !prevAddCase.status,
                      }));
                    }
                  }}
                />
                <span className="slider round"></span>
              </label>
              <span>Active</span>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning ms-2"
              onClick={handleClear}
            >
              Clear
            </button>
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-primary ms-2"
            >
              Submit
            </button>
          </div>
        </div>
      </Form>
    </>
  );
};
export default AddForm;
