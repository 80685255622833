import React, { useState, useEffect, useContext } from "react";
import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";
import { ApiContext } from "../apiContext";
import AddForm from "./AddForm";
import { v4 as uuidv4 } from "uuid";
import { getToken } from "../../auth/AuthService";
import axios from "axios";
import { useLoader } from "../../helper/Loader";
import notify from "../../helper/Notify";
import { isSpecialCharacter } from "../../helper/CommonFunction";

const Service = ({ closeModal, modalData, setModalData }) => {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);
  const { data, setData, error } = useContext(ApiContext);
  const [formError, setFormError] = useState({});
  const [contentError, setContentError] = useState(null);
  const getData = data.glossary.map((item) => item);
  const fieldsArray = ["description"];
  const apiBaseUrl = window.API_CONFIG.All_Api.glossary;
  const [dataSave, setDataSave] = useState({})
  const{setIsLoader} = useLoader();
  const token = getToken();
  const generateUUID = () => {
    const uuid = uuidv4();
    const fiveDigitUUID = uuid.replace(/-/g, "").substr(0, 5);
    return fiveDigitUUID;
  };
  function generateCustomID() {
    const timestamp = new Date().getTime().toString(); // Get current timestamp as a string
    const randomDigits = Math.floor(Math.random() * 100000).toString(); // Generate random digits

    return timestamp + randomDigits;
  }

  const [addCase, setCase] = useState({
    title: "",
    shortdescription: "",
    id: generateCustomID(),
    code: generateUUID(),
    createdDate: new Date().toISOString().slice(0, 10),
  });
  const getInputValue = (field) => {
    return modalData ? modalData[field] : addCase[field];
  };


   // For Jodit Editot use sep. datachange function
  const  contentDataChanged = (data, field) => {
    if (data.includes("http:")) {
      setContentError("Please use secure 'https' URLs only");
      return;
    }
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = data;
    const h1Count = tempDiv.getElementsByTagName("h1").length;
    if (h1Count > 1) {
      setContentError("Only one <h1> tag is permitted.");
      return;
    }
  
    setContentError(null);
  
      if (modalData) {
        setDataSave((prevDataSave) => ({
          ...prevDataSave,
          [field]: data,
        }));
      } else {
      setCase((prevAddCase) => ({
        ...prevAddCase,
        [field]: data,
      }));
    }
  };

  /*---- change input field function ----*/
  const handleInputChange = (event, field) => {
    const { value } = event.target;
    if (modalData) {
      setModalData((prevModalData) => ({
        ...prevModalData,
        [field]: value,
      }));
    } else {
      setCase((prevAddCase) => ({
        ...prevAddCase,
        [field]: value,
      }));
    }
  };


  
// Generate custom url on title value
useEffect(() => {
  const generateCustomURL = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)+/g, '');
  };

  if (modalData) {
    setModalData((prevModalData) => ({
      ...prevModalData,
      customurl: generateCustomURL(modalData.title),
    }));
  } else {
    setCase((prevAddCase) => ({
      ...prevAddCase,
      customurl: generateCustomURL(addCase.title),
    }));
  }
}, [addCase.title, modalData?.title]);

  /*---- handle clear function----*/
  const handleClear = () => {
    const clearfields = {
      title: "",
      shortdescription: "",
      status: false,
      descriptionflag: false,
      customurlflag: false,
      customurl: "",
      description: "",
    };
    if (!modalData) {
      setCase(clearfields);
    } else {
      setModalData((prevModalData) => ({
        ...prevModalData,
        ...clearfields,
      }));
    }
  };

  /*---- validation on form fields----*/
  const validateFormFields = () => {
    const errors = {};
    const fieldarr = ["title"];
    const validatefield = (field) => {
      if (!modalData && addCase[field] === "") {
        errors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required.`;
      }
      if (modalData && modalData[field] === "") {
        errors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required.`;
      }
    };
    fieldarr.forEach(validatefield);

    return errors;
  };

  async function processAndUploadImages(modalData, addCase, fieldsArray) {
    for (const field of fieldsArray) {
      const htmlContent = modalData ? modalData[field] : addCase[field];
      if (!htmlContent) {
        continue; // Skip to the next field
      }
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = htmlContent;
      const imgElements = Array.from(tempDiv.querySelectorAll("img"));

      const base64ToBlob = (base64, mimeType) => {
        const byteCharacters = atob(base64);
        const byteArray = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteArray[i] = byteCharacters.charCodeAt(i);
        }
        return new Blob([byteArray], { type: mimeType });
      };

      const processImage = async (img) => {
        const src = img.getAttribute("src");
        if (src && src.startsWith("data:image/")) {
          const parts = src.split(";");
          const mimeType = parts[0].split(":")[1];
          const imageData = parts[1].split(",")[1];
          const blob = base64ToBlob(imageData, mimeType);
          const file = new File([blob], "image.png", { type: mimeType });

          const formData = new FormData();
          formData.append("upload", file);
          formData.append(
            "ckCsrfToken",
            "2fDpPNU1D4C9z19x1f0XZTAaH6nZlR8DoiH950V5"
          );

          try {
            const response = await fetch(window.API_CONFIG.imageStringUrl, {
              method: "POST",
              body: formData,
            });

            if (response.ok) {
              const data = await response.json();
              const imgUrl = data.url;
              img.setAttribute("src", imgUrl);
            } else {
              console.error("Error uploading image:", response.statusText);
            }
          } catch (error) {
            console.error("Error processing image:", error);
          }
        }
      };

      const processImagesSequentially = async () => {
        for (const img of imgElements) {
          await processImage(img);
        }
        if (modalData) {
          modalData[field] = tempDiv.innerHTML;
        } else {
          addCase[field] = tempDiv.innerHTML;
        }
      };

      await processImagesSequentially();
    }
  }
  /* form submit function*/
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formErrors = validateFormFields();

    if (Object.keys(formErrors).length > 0) {
      setFormError({ ...formErrors });
      notify.warning();
      return; 
    }

    setFormError(""); 
    setIsLoader(true)

    try {
      if (modalData) {
        const updatedModalData = { ...modalData, ...dataSave };
        await processAndUploadImages(updatedModalData, null, fieldsArray);
        // Updating an existing data entry
        const updatedData = {
          ...updatedModalData,
          updatedDate: new Date().toISOString().slice(0, 10),
        };
        const duplicateItem = getData.find(
          (item) => item.title === modalData.title && item.id !== modalData.id // Make sure it's not the same item being updated
        );

        if (duplicateItem) {
          notify.error("Data is a duplicate and cannot be updated.");
          return;
        }

        if(contentError){
          notify.error(contentError);
          return;
        }

        if (isSpecialCharacter(modalData.title.charAt(0))){
          notify.error("Title should not start with special character");
             return;
        }

        try {
          const response = await axios.post(apiBaseUrl, updatedData, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "x-access-token": token,
            },
          });
          console.log("Data successfully updated:", response.data);

          if(response.data.success === true){
          const updatedDataResponse = await axios.get(apiBaseUrl, {
            headers: {
              "x-access-token": token,
            },
          });
          const updatedDataFromServer =
            updatedDataResponse.data.data?.ViewModels || [];

          setData((prevData) => ({
            ...prevData,
            glossary: updatedDataFromServer,
          }));

          closeModal();
          notify.success(response.data.message);
          setCase({}); 
        }
        else{
          notify.error(response.data.message);
        }
        } catch (error) {
          console.error("Error updating data:", error);
          notify.error(error.message);
        }
      } else {
        // Adding a new data entry
        await processAndUploadImages(null, addCase, fieldsArray);
        try {
          const updatedBlogData = {
            ...addCase,
            createdDate: new Date().toISOString().slice(0, 10),
          };
          const duplicateItem = getData.find(
            (item) => item.title === addCase.title
          );

          if (duplicateItem) {
            notify.error("Data is a duplicate and cannot be updated.");
            return;
          }
          if(contentError){
            notify.error(contentError);
            return;
          }

          if (isSpecialCharacter(addCase.title.charAt(0))){
            notify.error("Title should not start with special character");
               return;
          }

          const response = await axios.post(apiBaseUrl, updatedBlogData, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "x-access-token": token,
            },
          });

          console.log("New data added successfully:", response.data);

        if(response.data.success === true){
          const updatedBlogResponse = await axios.get(apiBaseUrl, {
            headers: {
              "x-access-token": token,
            },
          });
          const updatedDataFromServer =
            updatedBlogResponse.data.data?.ViewModels || [];

          setData((prevData) => ({
            ...prevData,
            glossary: updatedDataFromServer,
          }));
          closeModal();
          notify.success(response.data.message);
          setCase({}); 
        }
        else{
          notify.error(response.data.message);
        }
        } catch (error) {
          console.error("Error adding new data:", error);
          notify.error(error.message);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      notify.error(error.message)
    }
    finally{
      setIsLoader(false);
    }
  };
if(error){
  return <div>Error: {error.message}</div>;
}
  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>{modalData ? "Edit Glossary" : "Add New Glossary"}</h1>
          <div className="close-icon">
            <FaWindowClose onClick={closeModal} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <AddForm
                handleSubmit={handleSubmit}
                formError={formError}
                getInputValue={getInputValue}
                handleInputChange={handleInputChange}
                handleClear={handleClear}
                modalData={modalData}
                addCase={addCase}
                setModalData={setModalData}
                contentDataChanged={contentDataChanged}
                setCase={setCase}
                closeModal={closeModal}
              />
            </CardBody>
            <div className="avc"></div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Service;
