import * as FaIcons from "react-icons/fa";
import * as FaIconSix from "react-icons/fa6";
export const ViewModels = [
  {
    Title: "DASHBOARD",
    State: "dashboard",
    Icon: <FaIcons.FaHome />,
    
    Visible: "false",
    Href: "/dashboard",
    NavListClass: "nav-item vmo-nav-link",
    Id: 1,
    Code: "NAV001",
    SubMenus: [],
    Tooltip: "Dashboard Tooltip",
  },
  {
    Title: "BLOG",
    State: "blog",
    Icon: <FaIconSix.FaMessage />,
    Visible: "true",
    Href: "/blog",
    NavListClass: "nav-item vmo-nav-link",
    menuOrder: 1,
    Id: 2,
    Code: "NAV002",
    SubMenus: [],
  },
  {
    Title: "Case Studies",
    State: "casestudies",
    Icon: <FaIcons.FaClipboardList />,
    Visible: "true",
    Href: "/casestudies",
    NavListClass: "nav-item vmo-nav-link",
    menuOrder: 2,
    Id: 3,
    Code: "NAV002",
    SubMenus: [],
  },
  {
    Title: "Ebooks",
    State: "ebooks",
    Icon: <FaIcons.FaLaptopCode />,
    Visible: "true",
    Href: "/ebooks",
    NavListClass: "nav-item vmo-nav-link",
    menuOrder: 2,
    Id: 3,
    Code: "NAV002",
    SubMenus: [],
  },
  {
    Title: "Career",
    State: "career",
    Icon: <FaIconSix.FaChartLine />,
    Visible: "true",
    Href: "/career",
    NavListClass: "nav-item vmo-nav-link",
    menuOrder: 3,
    Id: 4,
    Code: "NAV003",
    SubMenus: [],
  },
  {
    Title: "Events",
    State: "event",
    Icon: <FaIcons.FaCalendarDay />,
    Visible: "true",
    Href: "/event",
    NavListClass: "nav-item vmo-nav-link",
    menuOrder: 3,
    Id: 4,
    Code: "NAV003",
    SubMenus: [],
  },
  {
    Title: "AWARD",
    State: "award",
    Icon: <FaIcons.FaAward />,
    Visible: "true",
    Href: "/award",
    NavListClass: "nav-item vmo-nav-link",
    Id: 5,
    Code: "NAV003",
    SubMenus: [],
  },
  {
    Title: "TESTIMONIALS",
    State: "testimonials",
    Icon: <FaIcons.FaUserEdit />,
    Visible: "true",
    Href: "/testimonials",
    NavListClass: "nav-item vmo-nav-link",
    Id: 7,
    Code: "NAV004",
    SubMenus: [],
  },
  {
    Title: "Redirect Url List",
    State: "urllist",
    Icon: <FaIcons.FaLink />,
    Visible: "true",
    Href: "/urllist",
    NavListClass: "nav-item vmo-nav-link",
    Id: 7,
    Code: "NAV004",
    SubMenus: [],
  },
  {
    Title: "TESTIMONIALS VIDEOS",
    State: "video",
    Icon: <FaIcons.FaFileVideo />,
    Visible: "true",
    Href: "/video",
    NavListClass: "nav-item vmo-nav-link",
    Id: 8,
    Code: "NAV005",
    SubMenus: [],
  },
  {
    Title: "VIDEOS",
    State: "videos",
    Icon: <FaIcons.FaVideo />,
    Visible: "true",
    Href: "/videos",
    NavListClass: "nav-item vmo-nav-link",
    Id: 19,
    Code: "NAV225",
    SubMenus: [],
  },
  {
    Title: "Webinar",
    State: "webinar",
    Icon: <FaIconSix.FaUsersRectangle />,
    Visible: "false",
    Href: "/webinar",
    NavListClass: "nav-item vmo-nav-link",
    Id: 111,
    Code: "NAV006",
    SubMenus: [],
  },
  {
    Title: "Page Video",
    State: "pagevideo",
    Icon: <FaIcons.FaRegFileVideo />,
    Visible: "false",
    Href: "/pagevideo",
    NavListClass: "nav-item vmo-nav-link",
    Id: 19,
    Code: "NAV301",
    SubMenus: [],
  },
  {
    Title: "Frequently Asked Questions",
    State: "faqs",
    Icon: <FaIcons.FaQuestionCircle />,
    Visible: "false",
    Href: "/faqs",
    NavListClass: "nav-item vmo-nav-link",
    Id: 20,
    Code: "NAV020",
    SubMenus: [],
  },
  {
    Title: "Glossary",
    State: "glossary",
    Icon: <FaIcons.FaBuffer />,
    Visible: "false",
    Href: "/glossary",
    NavListClass: "nav-item vmo-nav-link",
    Id: 20,
    Code: "NAV029",
    SubMenus: [],
  },
  {
    Title: "Interested Product Mapping",
    State: "ipmapping",
    Icon: <FaIcons.FaDiceD6 />,
    Visible: "false",
    Href: "/ipmapping",
    NavListClass: "nav-item vmo-nav-link",
    Id: 111,
    Code: "NAV006",
    SubMenus: [],
  },

  {
    Title: "Ads",
    State: "ads",
    Icon: <FaIcons.FaAdversal />,
    Visible: "false",
    Href: "/ads",
    NavListClass: "nav-item vmo-nav-link",
    Id: 111,
    Code: "NAV006",
    SubMenus: [],
  },

  {
    Title: "Email",
    State: "email",
    Icon: <FaIcons.FaMailBulk />,
    Visible: "false",
    Href: "/email",
    NavListClass: "nav-item vmo-nav-link",
    Id: 111,
    Code: "NAV006",
    SubMenus: [],
  },

  {
    Title: "MetaData",
    State: "metaTag",
    Icon: <FaIcons.FaSearchengin />,
    Visible: "false",
    Href: "/metaTag",
    NavListClass: "nav-item vmo-nav-link",
    Id: 9,
    Code: "NAV006",
    SubMenus: [],
  },

  {
    Title: "Leads",
    State: "leads",
    Icon: <FaIcons.FaRegHandshake />,
    Visible: "false",
    Href: "/leads",
    NavListClass: "nav-item vmo-nav-link",
    Id: 113,
    Code: "NAV006",
    SubMenus: [],
  },
  {
    Title: "MASTER(S)",
    State: "blockemaillist",
    Icon: <FaIcons.FaClipboardList />,
    Visible: "false",
    NavListClass: "nav-item vmo-nav-link",
    Href: "/master/category",
    SubMenus: [
      {
        Title: "CATEGORY",
        State: "postCategory",
        Icon: <FaIcons.FaRegListAlt />,
        SubMenus: [],
        Href: "/master/category",
        ToolTip: "CATEGORY",
        Parent: "Masters",
        class: "",
        Id: 1,
        Code: "PCATEGORY",
      },
      {
        Title: "VIDEOS CATEGORY",
        State: "videoscategory",
        Icon: <FaIcons.FaVideo />,
        SubMenus: [],
        Href: "/master/videoscategory",
        ToolTip: "VIDEOS CATEGORY",
        Parent: "Masters",
        class: "",
        Id: 1,
        Code: "PCATEGORY",
      },
      {
        Title: "Language",
        State: "language",
        Icon: <FaIcons.FaLanguage />,
        SubMenus: [],
        Href: "/master/language",
        ToolTip: "Language",
        Parent: "Masters",
        class: "",
        Id: 1,
        Code: "CLCATEGORY",
      },
      {
        Title: "Country",
        State: "country",
        Icon: <FaIcons.FaGlobe />,
        SubMenus: [],
        Href: "/master/country",
        ToolTip: "Country",
        Parent: "Masters",
        class: "",
        Id: 1,
        Code: "CACATEGORY",
      },
      {
        Title: "CAREER CATEGORY",
        State: "careerCategory",
        Icon: <FaIcons.FaChartLine />,
        SubMenus: [],
        Href: "/master/careercategory",
        ToolTip: "CAREER CATEGORY",
        Parent: "Masters",
        class: "",
        Id: 1,
        Code: "PCATEGORY",
      },
      {
        Title: "EBOOKS CATEGORY",
        State: "ebookscategory",
        Icon: <FaIcons.FaBookReader />,
        SubMenus: [],
        Href: "/master/ebookscategory",
        ToolTip: "EBOOKS CATEGORY",
        Parent: "Masters",
        class: "",
        Id: 1,
        Code: "PCATEGORY",
      },
      {
        Title: "Block Email List",
        State: "blockemaillist",
        Icon: <FaIcons.FaBan />,
        SubMenus: [],
        Href: "/master/blockemaillist",
        ToolTip: "Block Mail List",
        Parent: "Masters",
        class: "",
        Id: 1,
        Code: "PCATEGORY",
      },
      {
        Title: "Case Studies Category",
        State: "caseStudiesCategory",
        Icon: <FaIcons.FaClipboardList />,
        SubMenus: [],
        Href: "/master/caseStudiesCategory",
        ToolTip: "Case Studies Category",
        Parent: "Masters",
        class: "",
        Id: 1,
        Code: "CCATEGORY",
      },
      {
        Title: "Interested Product",
        State: "interestedproduct",
        Icon: <FaIcons.FaDiceD6 />,
        SubMenus: [],
        Href: "/master/interestedproduct",
        ToolTip: "Interested Product",
        Parent: "Masters",
        class: "",
        Id: 1,
        Code: "IPCATEGORY",
      },
      
       {
        Title: "MailEventType",
        State: "maileventtype",
        Icon: <FaIcons.FaMailBulk />,
        SubMenus: [],
        Href: "/master/maileventtype",
        ToolTip: "MailEventType",
        Parent: "Masters",
        class: "",
        Id: 1,
        Code: "METCATEGORY",
      },

      {
        Title: "Solutions",
        State: "solutions",
        Icon: <FaIcons.FaUserCircle />,
        SubMenus: [],
        Href: "/master/solutions",
        ToolTip: "Solutions",
        Parent: "Masters",
        class: "",
        Id: 1,
        Code: "SOCATEGORY",
      },
      
    ],
    Id: 10,
    Code: "NAV006",
  },
];
